var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-qy flex-direction-column no-scrollbar" },
    [
      _c("div", { staticClass: "vip-wrap" }, [
        _vm._m(0),
        _c("div", { staticClass: "vip-info" }, [
          _c("span", { staticClass: "vip-label" }, [
            _vm._v(" 版本信息： "),
            _c("span", { staticClass: "vip-value" }, [
              _vm._v(" " + _vm._s(_vm.userInfo.userMember.desc) + " "),
            ]),
          ]),
          _c("span", { staticClass: "vip-label" }, [
            _vm._v(" 版本状态： "),
            _c("span", { staticClass: "vip-value" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.userInfo.userMember.status ? "使用中" : "已过期") +
                  " "
              ),
            ]),
          ]),
          _c("span", { staticClass: "vip-label" }, [
            _vm._v(" 付费类型： "),
            _c("span", { staticClass: "vip-value" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.userInfo.userMember.key === "vip" ? "已付费" : "未付费"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("span", { staticClass: "vip-label" }, [
            _vm._v(" 到期时间： "),
            _c("span", { staticClass: "vip-value" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.userInfo.userMember.key === "normal"
                      ? "--"
                      : _vm.userInfo.userMember.endTime
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _vm.userInfo.userMember.key === "normal"
          ? _c("div", { staticClass: "vip-btn-wrap" }, [
              _c("div", { staticClass: "vip-btn-info" }),
              _c(
                "div",
                { staticClass: "vip-button", on: { click: _vm.vipClick } },
                [_vm._v("去升级")]
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "vip-order-list" },
        [
          _vm._m(1),
          _c(
            "Table",
            {
              staticClass: "table-view",
              attrs: { border: "", columns: _vm.columns, data: _vm.list },
              scopedSlots: _vm._u([
                {
                  key: "duration",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.duration[row.productCode]) + " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "discount",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.payTdiscountype === "qianlimu-1"
                                ? "新客专享"
                                : "--"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "payType",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(row.payType === 1 ? "微信" : "支付宝") +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "payStatus",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.payStatus[row.payStatus]) + " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "actuallyPay",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(" ¥" + _vm._s(row.actuallyPay) + " "),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "productName" }, [
                _c("span", [_vm._v(" VIP会员 ")]),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "page-wrap" },
            [
              _c("Page", {
                attrs: {
                  "show-sizer": "",
                  "show-total": "",
                  "show-elevator": "",
                  total: _vm.total,
                  "page-size": _vm.size,
                  "page-size-opts": _vm.sizeOpts,
                },
                on: {
                  "on-change": _vm.handlePageChange,
                  "on-page-size-change": _vm.handlerPageSizeChange,
                  "on-prev": _vm.handlePageChange,
                  "on-next": _vm.handlePageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalUpgrade", {
        attrs: { isShow: _vm.isShow },
        on: {
          close: function ($event) {
            _vm.isShow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vip-title" }, [
      _c("span", { staticClass: "icon-vertical" }),
      _vm._v(" 我的权益 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vip-title" }, [
      _c("span", { staticClass: "icon-vertical" }),
      _vm._v(" 订单记录 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }