<template>
  <div class="my-collect">
    <div class="tab-bar">
      <div
        class="tab-item"
        :class="{ active: isActive === index }"
        v-for="(item, index) in tabBar"
        @click="changeTabbar(item, index)"
        :key="item.label + index"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="goods-list" v-if="collectList.length > 0">
      <div class="good-item" v-for="(item, index) in collectList" :key="index">
        <div class="left">
          <div class="img-wrap">
            <img
              :src="item.logo || defaultImg"
              alt=""
              v-if="item.indexType !== 'data_observation_business_strict'"
            />
            <img
              :src="showBgPic(index)"
              v-if="item.indexType === 'data_observation_business_strict'"
              alt=""
            />
            <span
              class="business-name"
              v-if="item.indexType === 'data_observation_business_strict'"
              >{{ item.name }}</span
            >
          </div>
          <div class="goods-msg">
            <div class="msg-top">{{ item.name }}</div>
            <div
              class="msg-bottom"
              v-show="item.indexType === 'data_observation_brand'"
            >
              <div class="msg-item">
                <span class="msg-name">类别：</span>
                <span class="msg-val">{{ item.category || "--" }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">所属公司：</span>
                <span class="msg-val">{{ item.companyName }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">成立时间：</span>
                <span class="msg-val">{{ item.startTime || "--" }}</span>
              </div>
            </div>

            <div
              class="msg-bottom"
              v-show="item.indexType === 'data_observation_location'"
            >
              <div class="msg-item">
                <span class="msg-name">地址：</span>
                <span class="msg-val">{{ item.location || "--" }}</span>
              </div>
              <div class="msg-item" v-show="item.tags">
                <span class="msg-name">类别：</span>
                <span class="msg-val">{{ getTags(item.tags) || "--" }}</span>
              </div>
            </div>

            <div
              class="msg-bottom"
              v-show="item.indexType === 'data_observation_business_strict'"
            >
              <div class="msg-item">
                <span class="msg-name">商圈级别：</span>
                <span class="msg-val">{{ item.level }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">商圈等级：</span>
                <span class="msg-val">{{ item.type }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">商圈商业项目：</span>
                <span class="msg-val">{{ item.childrenNumber }}个</span>
              </div>
            </div>

            <div
              class="msg-bottom"
              v-show="item.indexType === 'data_observation_mall'"
            >
              <div class="msg-item">
                <span class="msg-name">地址：</span>
                <span class="msg-val">{{ item.addr }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">开业时间：</span>
                <span class="msg-val">{{ item.startTime || "--" }}</span>
              </div>
            </div>

            <div
              class="msg-bottom"
              v-show="item.indexType === 'data_observation_region'"
            >
              <div class="msg-item">
                <span class="msg-name">城市级别：</span>
                <span class="msg-val">{{ item.cityLevel }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">城市线级：</span>
                <span class="msg-val">{{ item.cityLineLevel }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">常住人口：</span>
                <span class="msg-val">{{ item.residentPopulation }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="collect" @click="delCollect(item)">取消收藏</div>
          <div class="detail" @click="goBrandDetail(item)">
            <span>查看详情</span>
            <img src="@/assets/images/icon/arrow_blue.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <NoData
      v-if="collectList.length === 0"
      :img="'/images/no-data2.png'"
      :text="'暂无相关数据'"
    />
    <div class="page-wrap">
      <Page
        show-sizer
        :total="pageTotal"
        show-total
        show-elevator
        :page-size="pageSize"
        :page-size-opts="[pageSize]"
        @on-change="handlePageChange"
        @on-page-size-change="handlerPageSizeChange"
        @on-prev="handlePageChange"
        @on-next="handlePageChange"
      />
    </div>
  </div>
</template>
<script>
import { User, GetDetailBrowsingRecord } from "@/service";
import { openNewWindow } from "@/assets/javascript/utils.js";
import normalPic from "@/assets/images/business/logobg1.png";
import normalPic2 from "@/assets/images/business/logobg2.png";
export default {
  name: "myCollect",
  data() {
    return {
      isActive: 1,
      tabBar: [
        { tag: "location", sort: 1, label: "位置" },
        { tag: "brand", sort: 2, label: "品牌" },
        { tag: "mall", sort: 3, label: "商场" },
        { tag: "business", sort: 4, label: "商圈" },
        { tag: "city", sort: 5, label: "城市" },
      ],
      pageSize: 20,
      pageNum: 1,
      pageTotal: 100,
      collectList: [],
      defaultImg: require("@/assets/images/normal_brand.png"),
    };
  },
  methods: {
    changeTabbar(item, index) {
      this.isActive = index;
      this.pageNum = 1;
      this.getCollectList(this.tabBar[this.isActive]);
    },
    handlePageChange(page) {
      this.pageNum = page;
      this.getCollectList(this.tabBar[this.isActive]);
    },
    handlerPageSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      this.getCollectList(this.tabBar[this.isActive]);
    },
    // 取消收藏
    async delCollect(item) {
      let that = this;
      this.$global.confirm({
        title: "提示",
        content: "确认要取消收藏吗？",
        ok: async () => {
          let params = {
            sort: that.tabBar[that.isActive].sort,
            code: item.code,
          };
          let res = await User.deleteCollection(params);
          if (res && res.code === 200) {
            that.getCollectList(that.tabBar[that.isActive]);
            that.$Message.info((res && res.msg) || "取消成功");
          }
        },
      });
    },
    getTags(_tags) {
      let tags = _tags;
      if (!tags) {
        return "";
      }
      if (typeof tags === "string") {
        tags = tags.split(",");
      }
      let list = [];
      for (let i = 0, n = tags.length; i < n; i++) {
        let tag = tags[i];
        if (tag) {
          list.push(tag);
        }
        if (list.length === 2) {
          break;
        }
      }
      return list.join("/");
    },
    // 获取收藏列表
    async getCollectList(item) {
      let params = {
        sort: item.sort,
        page: this.pageNum,
        pageSize: this.pageSize,
      };
      let res = await User.getCollectionList(params);
      if (res && res.code === 200) {
        let list = res.data.items;
        this.collectList = list;
        this.pageTotal = res.data.pages.total;
      }
    },
    //初始化商圈头像
    showBgPic(val) {
      if (val % 2 === 0) {
        return normalPic2;
      }
      return normalPic;
    },
    //去详情
    async goBrandDetail(item) {
      if (item.indexType === "data_observation_brand") {
        const res = await GetDetailBrowsingRecord({
          module: "brandDetail",
          detailID: item.code,
        });
        if (res && res.code == 200 && res.data == "success") {
          openNewWindow("/brandDetail", {
            id: item.code,
          });
        }
      } else if (item.indexType === "data_observation_mall") {
        const res = await GetDetailBrowsingRecord({
          module: "mallDetail",
          detailID: item.code,
        });
        if (res && res.code == 200 && res.data == "success") {
          openNewWindow("/mallDetail", {
            id: item.code,
          });
        }
      } else if (item.indexType === "data_observation_business_strict") {
        const res = await GetDetailBrowsingRecord({
          module: "businessDetail",
          detailID: item.code,
        });
        if (res && res.code == 200 && res.data == "success") {
          openNewWindow("/businessDetail", {
            id: item.code,
          });
        }
      } else if (item.indexType === "data_observation_location") {
        // openNewWindow("/location", {
        //   id: item.code,
        // });
        this.$Message.info("敬请期待!");
      } else if (item.indexType === "data_observation_region") {
        const res = await GetDetailBrowsingRecord({
          module: "cityDetail",
          detailID: item.code,
        });
        if (res && res.code == 200 && res.data == "success") {
          openNewWindow("/cityDetail", {
            id: item.code,
          });
        }
      }
    },
  },
  created() {
    this.changeTabbar(this.tabBar[0], 0);
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.my-collect {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  overflow: auto;
  .tab-bar {
    height: 56px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 32px;
    .tab-item {
      margin-right: 40px;
      height: 56px;
      cursor: pointer;
      position: relative;
      padding-top: 24px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
      line-height: 20px;
    }
    .active {
      color: #3519fb;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background: #3519fb;
        border-radius: 1px;
      }
    }
  }
  .goods-list {
    height: calc(100% - 135px - 57px);
    overflow: auto;
    .good-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      height: 120px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 32px;
        right: 32px;
        height: 1px;
        opacity: 0.3;
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .img-wrap {
          width: 80px;
          height: 80px;
          border-radius: 4px;
          overflow: hidden;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
          }
          .business-name {
            position: absolute;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
            width: 70px;
          }
        }

        .goods-msg {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .msg-top {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            line-height: 16px;
            margin-bottom: 21px;
          }
          .msg-bottom {
            display: flex;
            .msg-item {
              margin-right: 24px;
              span {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
              }
              .msg-name {
                color: rgba(0, 0, 0, 0.4);
              }
              .msg-val {
                color: rgba(0, 0, 0, 0.6);
              }
            }
          }
        }
      }
      .right {
        .collect {
          cursor: pointer;
          width: 78px;
          height: 26px;
          background: rgba(0, 0, 0, 0.08);
          border-radius: 4px;

          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.6);
          line-height: 26px;

          margin-bottom: 21px;
        }
        .detail {
          cursor: pointer;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            font-size: 14px;
            font-weight: 500;
            color: #3519fb;
            line-height: 16px;
            margin-right: 6px;
            display: inline-block;
          }
          img {
            width: 6px;
            height: 10px;
          }
        }
      }
    }
  }
  .page-wrap {
    height: 135px;
    box-sizing: border-box;
    padding-top: 23px;
    padding-right: 32px;
    text-align: right;
  }
}
</style>
