<template>
  <div class="site-wrap user-wrap">
    <Header :mode="1" :isRevertIcon="true"></Header>
    <div class="main-wrap main-width">
      <div class="user-content">
        <div class="left">
          <UserMenu></UserMenu>
        </div>
        <div class="right">
          <component :is="pathName"></component>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import UserMenu from "@/components/user/UserMenu.vue";
import MyAccount from "@/components/user/MyAccount.vue";
import MyCollect from "@/components/user/MyCollect.vue";
import MyClaim from "@/components/user/MyClaim.vue";
import MyQy from "@/components/user/MyQy.vue";

export default {
  metaInfo: {
    title: "个人中心",
  },
  name: "user",
  data() {
    return {
      pathName: "",
    };
  },
    metaInfo: {
      title:"个人中心",
    meta: [
      {
        name: "keywords",
        content:
          "千里目,大数据,商业数据,数据查询,数据分析,数据可视化,用户画像,实时客流,客流统计,品牌监测,品牌销量,业态分布,周边配套,广告创意投放,人口热力图分布,商户分析,商业营销,全域数据,全国数据,时空大数据,产业洞察,门店洞察,开店选址,开关店数据,竞争对手分析,对比分析,深度分析,分析报告,数据上图,品牌排行,智能查询,商业决策,数字化",
      },
      {
        name: "description",
        content:
          "千里目全域商业数据平台，提供查品牌、查门店、查位置、查商圈、查商场、查城市、查客流、查周边、查创意等全域多维数据的查询分析服务，以数据驱动商业发展和决策，助力企业发现商业机会。",
      },
    ],
  },
  watch: {
    "$route.query": function (v) {
      if (this.$route.name === "user") {
        if (v.page) {
          this.pathName = v.page;
        }
      }
    },
  },
  methods: {
    menuChange(name) {
      this.pathName = name;
    },
    getRouteMse() {
      if (this.$route.name === "user") {
        if (!this.$route.query.page) {
          this.pathName = "myAccount";
        } else {
          this.pathName = this.$route.query.page;
        }
      } else {
        this.pathName = this.$route.query.page;
      }
    },
  },
  created() {
    this.getRouteMse();
  },
  components: {
    Footer,
    UserMenu,
    MyAccount,
    MyCollect,
    MyClaim,
    MyQy,
  },
};
</script>
<style lang="less" scoped>
.user-wrap {
  background-color: #f7f8fa;
  position: relative;
  overflow-y: auto;
  :deep(.main-width) {
    min-height: 744px;
  }
  :deep(.header-menus-color-mode1) {
    color: #000;
  }
  :deep(.header-wrap) {
    background-color: #fff;
  }
  :deep(.main-wrap) {
    background-color: #f7f8fa;
    height: calc(100% - 55px - 292px);
  }
  .user-content {
    height: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    .left {
      width: 182px;
      height: 100%;
    }
    .right {
      width: 1000px;
      height: 100%;
    }
  }
}
</style>
