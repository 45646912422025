<template>
  <div class="my-qy flex-direction-column no-scrollbar">
    <div class="vip-wrap">
      <div class="vip-title">
        <span class="icon-vertical"></span>
        我的权益
      </div>
      <div class="vip-info">
        <span class="vip-label">
          版本信息：
          <span class="vip-value">
            <!-- <img v-if="userInfo.userMember.key === 'vip'" class="vip-icon" src="../../assets/images/vip/vip-icon.png" alt=""> -->
            {{ userInfo.userMember.desc }}
          </span>
        </span>
        <span class="vip-label">
          版本状态：
          <span class="vip-value">
            {{ userInfo.userMember.status ? "使用中" : "已过期" }}
          </span>
        </span>

        <span class="vip-label">
          付费类型：
          <span class="vip-value">
            {{ userInfo.userMember.key === "vip" ? "已付费" : "未付费" }}
          </span>
        </span>

        <span class="vip-label">
          到期时间：
          <span class="vip-value">
            {{
              userInfo.userMember.key === "normal"
                ? "--"
                : userInfo.userMember.endTime
            }}
          </span>
        </span>
      </div>
      <div class="vip-btn-wrap" v-if="userInfo.userMember.key === 'normal'">
        <div class="vip-btn-info"></div>
        <!-- <div class="vip-button" @click="vipClick">查看会员套餐</div> -->
        <div class="vip-button" @click="vipClick">去升级</div>
      </div>
    </div>
    <div class="vip-order-list">
      <div class="vip-title">
        <span class="icon-vertical"></span>
        订单记录
      </div>
      <Table class="table-view" border :columns="columns" :data="list">
        <template slot="productName">
          <span> VIP会员 </span>
        </template>
        <template slot="duration" slot-scope="{ row }">
          <span>
            {{ duration[row.productCode] }}
          </span>
        </template>
        <template slot="discount" slot-scope="{ row }">
          <span>
            {{ row.payTdiscountype === "qianlimu-1" ? "新客专享" : "--" }}
          </span>
        </template>
        <template slot="payType" slot-scope="{ row }">
          <span>
            {{ row.payType === 1 ? "微信" : "支付宝" }}
          </span>
        </template>
        <template slot="payStatus" slot-scope="{ row }">
          <span>
            {{ payStatus[row.payStatus] }}
          </span>
        </template>
        <template slot="actuallyPay" slot-scope="{ row }">
          <span> ¥{{ row.actuallyPay }} </span>
        </template>
      </Table>
      <div class="page-wrap">
        <Page
          show-sizer
          show-total
          show-elevator
          :total="total"
          :page-size="size"
          :page-size-opts="sizeOpts"
          @on-change="handlePageChange"
          @on-page-size-change="handlerPageSizeChange"
          @on-prev="handlePageChange"
          @on-next="handlePageChange"
        />
      </div>
    </div>
    <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
  </div>
</template>
<script>
import { User } from "@/service";
import { mapGetters, mapMutations } from "vuex";
import ModalUpgrade from "@/components/ModalUpgrade.vue";
export default {
  name: "myQy",
  data() {
    return {
      isShow: false,
      duration: {
        "qianlimu-1": "7天",
        "qianlimu-2": "1个月",
        "qianlimu-3": "3个月",
        "qianlimu-4": "12个月"
      },
      columns: [
        {
          title: "订单编号",
          key: "outTradeNo"
        },
        {
          title: "支付时间",
          key: "buyTime"
        },
        {
          title: "购买版本",
          key: "productName",
          slot: "productName",
          width: "100"
        },
        {
          title: "购买时长",
          slot: "duration",
          width: "100"
        },
        {
          title: "优惠信息",
          key: "discount",
          slot: "discount",
          width: "100"
        },
        {
          title: "支付方式",
          key: "",
          slot: "payType",
          width: "100"
        },
        {
          title: "实付金额",
          key: "actuallyPay",
          slot: "actuallyPay",
          width: "100"
        },
        {
          title: "交易状态",
          key: "",
          slot: "payStatus",
          width: "100"
        }
      ],
      payStatus: {
        1: "待支付",
        2: "成功",
        3: "已退款",
        4: "失败",
        5: "订单超时"
      },
      list: [],
      page: 1,
      size: 20,
      total: 0,
      sizeOpts: [10, 20, 30],
      userInfo: {
        userMember: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      token: "getToken"
    })
  },
  methods: {
    //开通VIP
    vipClick() {
      // window.open("/vip", "_blank");
      this.isShow = true;
    },
    // 获取订单列表
    async getOrderList() {
      let params = {
        pageNum: this.page,
        pageSize: this.size
      };
      let res = await User.getOrderList(params);
      if (res && res.code === 200) {
        this.list = res.data.data || [];
        this.total = res.data.total || 0;
      }
    },
    // 分页处理
    handlePageChange(page) {
      this.page = page;
      this.getOrderList();
    },
    handlerPageSizeChange(size) {
      this.size = size;
      this.page = 1;
      this.getOrderList();
    },
    async getUserInfo() {
      let res = await User.getUserInfo();
      if (res && res.code === 200) {
        this.userInfo = res.data.items;
      }
    }
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    this.getOrderList();
    this.$nextTick(() => {
      if (this.$route.query.from === "vip") {
        window.location.href = "/user?page=myQy";
      }
    });
  },
  components: {
    ModalUpgrade
  }
};
</script>
<style lang="less" scoped>
.my-qy {
  width: 100%;
  height: 100%;
  position: relative;
  .vip-wrap {
    width: 100%;
    height: auto;
    min-height: 115px;
    background-color: #fff;
    padding: 20px 15px 28px 15px;
    border-radius: 4px;

    .vip-btn-wrap {
      padding-top: 50px;
      .vip-btn-info {
        width: 658px;
        height: 60px;
        margin: auto;
        // background-image: url("../../assets/images/vip/vip-bg.png");
        background-image: url("../../assets/images/vip/vip-bg2.png");
        background-repeat: no-repeat;
        background-size: 658px 66px;
      }
      .vip-button {
        cursor: pointer;
        margin: 0 auto;
        margin-top: 24px;
        width: 200px;
        height: 44px;
        // background: linear-gradient(315deg, #ebba88 0%, #f8dbaf 100%);
        background: linear-gradient(134deg, #8c92b7 0%, #43496e 100%);
        box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);
        border-radius: 4px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        // color: #6a3611;
        // color: #fff;
          color: #faeed8;
        line-height: 44px;
        &:hover {
          // background: linear-gradient(315deg, #d99651 0%, #ffd89d 100%);
          // color: #faeed8;
          // background: linear-gradient(315deg, #d99651 0%, #ffd89d 100%);

          background: linear-gradient(134deg, #707595 0%, #303553 100%);
        }
      }
    }

    .vip-info {
      margin-top: 24px;
      padding-left: 9px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      .vip-label {
        color: rgba(0, 0, 0, 0.4);
        margin-right: 70px;
        .vip-value {
          color: rgba(0, 0, 0, 0.8);
          // display: flex;
          // align-items: center;
          .vip-icon {
            vertical-align: middle;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .vip-title {
    display: flex;
    align-items: center;
  }
  .icon-vertical {
    display: inline-block;
    width: 3px;
    height: 12px;
    background: #3519fb;
    border-radius: 2px;
    margin-right: 6px;
  }
  .vip-order-list {
    background: #fff;
    margin-top: 18px;
    border-radius: 4px;
    min-height: 430px;
    // height: 100%;
    max-height: calc(100% - 115px - 20px);
    flex: 1;
    padding: 20px 15px 0 15px;
    position: relative;
    .table-view {
      margin-top: 16px;
      max-height: calc(100% - 20px - 16px - 55px);
      :deep(.ivu-table) {
        overflow-y: auto;
        .ivu-table-header {
          th {
            background: #f6f6fa;
            height: 54px;
          }
        }
        .ivu-table-tip {
          overflow-x: hidden;
        }
      }
    }
  }
  .page-wrap {
    // position: absolute;
    // bottom: 10px;
    height: 35px;
    width: calc(100% - 30px);
    text-align: right;
    box-sizing: border-box;
    margin-top: 20px;
  }
}
</style>
