<template>
  <div class="user-menu">
    <div
      class="menu-item"
      :class="{
        'active-menu-item':
          item.name === routeMsg.page && routeMsg.name === 'user',
      }"
      v-for="(item, index) in memnuList"
      :key="item.name + index"
      @click.stop="gotoPage(item)"
    >
      <div class="bg"></div>
      <img class="menu-img" :src="item.icon" alt="" />
      <img class="hover-menu-img" :src="item.hoverIcon" alt="" />
      <span>{{ item.title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "userMenu",
  data() {
    return {
      memnuList: [
        {
          name: "myAccount",
          icon: require("@/assets/images/icon/account@2x.png"),
          hoverIcon: require("@/assets/images/icon/account_hover@2x.png"),
          title: "账号管理",
        },
        {
          name: "myCollect",
          icon: require("@/assets/images/icon/collect@2x.png"),
          hoverIcon: require("@/assets/images/icon/collect_hover@2x.png"),
          title: "我的收藏",
        },
        {
          name: "myClaim",
          icon: require("@/assets/images/icon/claim@2x.png"),
          hoverIcon: require("@/assets/images/icon/claim_hover@2x.png"),
          title: "我的认领",
        },
        {
          name: "myQy",
          icon: require("@/assets/images/icon/qy@2x.png"),
          hoverIcon: require("@/assets/images/icon/qy_hover@2x.png"),
          title: "我的权益",
        },
      ],
      routeMsg: {
        name: "",
        page: "",
      },
    };
  },
  watch: {
    "$route.query": function (v) {
      if (this.$route.name === "user") {
        if (v.page) {
          this.getRouteMse();
        }
      }
    },
  },
  methods: {
    gotoPage(item) {
      if (this.routeMsg.query === item.name && this.routeMsg.name === "user") {
        return;
      }
      if (item.name === "myAccount") {
        this.$router.replace({ name: "user", query: { page: "myAccount" } });
      } else if (item.name === "myCollect") {
        this.$router.replace({ name: "user", query: { page: "myCollect" } });
      } else if (item.name === "myClaim") {
        this.$router.replace({ name: "user", query: { page: "myClaim" } });
      } else if (item.name === "myQy") {
        this.$router.replace({ name: "user", query: { page: "myQy" } });
      }
      this.getRouteMse();
    },
    getRouteMse() {
      this.routeMsg.name = this.$route.name;
      if (this.routeMsg.name === "user") {
        if (!this.$route.query.page) {
          this.routeMsg.page = "myAccount";
        } else {
          this.routeMsg.page = this.$route.query.page;
        }
      } else {
        this.routeMsg.page = this.$route.query.page;
      }
      this.$emit("change", this.routeMsg.page);
    },
  },
  mounted() {},
  created() {
    this.getRouteMse();
  },
};
</script>
<style lang="less" scoped>
.user-menu {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-top: 24px;
  .menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 36px;
    padding-left: 40px;
    position: relative;
    cursor: pointer;
    margin-bottom: 8px;

    &:hover {
      // &::after {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 2px;
      //   height: 36px;
      //   background: #3519fb;
      // }
      .menu-img {
        display: none;
      }
      .hover-menu-img {
        display: block;
      }
      span {
        color: #3519fb;
      }
    }
    .bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      // &:hover {
      //   background: linear-gradient(
      //     270deg,
      //     rgba(53, 25, 251, 0.26) 0%,
      //     #3519fb 100%
      //   );
      //   opacity: 0.06;
      // }
    }

    img {
      width: 14px;
      height: 14px;
    }
    .menu-img {
      display: block;
    }
    .hover-menu-img {
      display: none;
    }
    span {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      line-height: 20px;
    }
  }
  .active-menu-item {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 36px;
      background: #3519fb;
    }
    .bg {
      background: linear-gradient(
        270deg,
        rgba(53, 25, 251, 0.26) 0%,
        #3519fb 100%
      );
      opacity: 0.06;
    }
    .menu-img {
      display: none;
    }
    .hover-menu-img {
      display: block;
    }
    span {
      color: #3519fb;
    }
  }
}
</style>
