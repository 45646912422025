var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-claim" },
    [
      _vm.collectList.length > 0
        ? _c(
            "div",
            { staticClass: "goods-list" },
            _vm._l(_vm.collectList, function (item, index) {
              return _c("div", { key: index, staticClass: "good-item" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "img-wrap" }, [
                    _c("img", {
                      attrs: {
                        src: (item.info && item.info.logo) || _vm.defaultImg,
                        alt: "",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "goods-msg" }, [
                    _c("div", { staticClass: "msg-top" }, [
                      _vm._v(_vm._s(item.brandName)),
                    ]),
                    _c("div", { staticClass: "msg-bottom" }, [
                      _c("div", { staticClass: "msg-item" }, [
                        _c("span", { staticClass: "msg-name" }, [
                          _vm._v("类别："),
                        ]),
                        _c("span", { staticClass: "msg-val" }, [
                          _vm._v(_vm._s(item.info.category || "--")),
                        ]),
                      ]),
                      _c("div", { staticClass: "msg-item" }, [
                        _c("span", { staticClass: "msg-name" }, [
                          _vm._v("所属公司："),
                        ]),
                        _c("span", { staticClass: "msg-val" }, [
                          _vm._v(_vm._s(item.info.companyName || "--")),
                        ]),
                      ]),
                      _c("div", { staticClass: "msg-item" }, [
                        _c("span", { staticClass: "msg-name" }, [
                          _vm._v("成立时间："),
                        ]),
                        _c("span", { staticClass: "msg-val" }, [
                          _vm._v(_vm._s(item.info.startTime || "--")),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.status === 0,
                          expression: "item.status === 0",
                        },
                      ],
                      staticClass: "collect",
                      class: { loading: item.status === 0 },
                      on: {
                        click: function ($event) {
                          return _vm.delCollect(item)
                        },
                      },
                    },
                    [_vm._v(" 审核中 ")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.status === 1,
                          expression: "item.status === 1",
                        },
                      ],
                      staticClass: "collect",
                      class: { success: item.status === 1 },
                      on: {
                        click: function ($event) {
                          return _vm.delCollect(item)
                        },
                      },
                    },
                    [_vm._v(" 认领成功 ")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.status === 2,
                          expression: "item.status === 2",
                        },
                      ],
                      staticClass: "collect",
                      class: { fail: item.status === 2 },
                      on: {
                        click: function ($event) {
                          return _vm.delCollect(item)
                        },
                      },
                    },
                    [_vm._v(" 认领失败 ")]
                  ),
                  _c("div", { staticClass: "hint-wrap" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.status === 2,
                            expression: "item.status === 2",
                          },
                        ],
                        staticClass: "detail",
                        on: {
                          click: function ($event) {
                            return _vm.repeatClaim(item)
                          },
                        },
                      },
                      [_c("span", [_vm._v("再次认领")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "detail",
                        on: {
                          click: function ($event) {
                            return _vm.gotoPage(item)
                          },
                        },
                      },
                      [
                        _c("span", [_vm._v("查看详情")]),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icon/arrow_blue.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _c("BrandClaim", {
        attrs: {
          propsData: _vm.itemData,
          showMode: _vm.showMode,
          auto: false,
          callback: _vm.claimSuccessCallback,
        },
        on: {
          close: function ($event) {
            _vm.showMode = false
          },
        },
      }),
      _vm.collectList.length === 0
        ? _c("NoData", {
            attrs: { img: "/images/no-data2.png", text: "暂无相关数据" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }