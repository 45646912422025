<template>
  <div class="my-claim">
    <div class="goods-list" v-if="collectList.length > 0">
      <div class="good-item" v-for="(item, index) in collectList" :key="index">
        <div class="left">
          <div class="img-wrap">
            <img :src="(item.info && item.info.logo) || defaultImg" alt="" />
          </div>
          <div class="goods-msg">
            <div class="msg-top">{{ item.brandName }}</div>
            <div class="msg-bottom">
              <div class="msg-item">
                <span class="msg-name">类别：</span>
                <span class="msg-val">{{ item.info.category || "--" }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">所属公司：</span>
                <span class="msg-val">{{ item.info.companyName || "--" }}</span>
              </div>
              <div class="msg-item">
                <span class="msg-name">成立时间：</span>
                <span class="msg-val">{{ item.info.startTime || "--" }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div
            class="collect"
            :class="{ loading: item.status === 0 }"
            @click="delCollect(item)"
            v-show="item.status === 0"
          >
            审核中
          </div>
          <div
            class="collect"
            :class="{ success: item.status === 1 }"
            @click="delCollect(item)"
            v-show="item.status === 1"
          >
            认领成功
          </div>
          <div
            class="collect"
            :class="{ fail: item.status === 2 }"
            @click="delCollect(item)"
            v-show="item.status === 2"
          >
            认领失败
          </div>
          <div class="hint-wrap">
            <div
              class="detail"
              v-show="item.status === 2"
              @click="repeatClaim(item)"
            >
              <span>再次认领</span>
            </div>
            <div class="detail" @click="gotoPage(item)">
              <span>查看详情</span>
              <img src="@/assets/images/icon/arrow_blue.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <BrandClaim
      :propsData="itemData"
      :showMode="showMode"
      :auto="false"
      :callback="claimSuccessCallback"
      @close="showMode = false"
    />
    <NoData
      v-if="collectList.length === 0"
      :img="'/images/no-data2.png'"
      :text="'暂无相关数据'"
    />
  </div>
</template>
<script>
import { User, GetDetailBrowsingRecord } from "@/service";
import { openNewWindow } from "@/assets/javascript/utils.js";
import BrandClaim from "@/components/brandClaim/BrandClaim.vue";
import NoData from "@/components/NoData.vue";
export default {
  name: "myClaim",
  data() {
    return {
      defaultImg: require("@/assets/images/normal_brand.png"),
      itemData: {},
      showMode: false,
      collectList: [],
    };
  },
  methods: {
    delCollect() {},
    getTags(_tags) {
      let tags = _tags;
      if (!tags) {
        return "";
      }
      if (typeof tags === "string") {
        tags = tags.split(",");
      }
      let list = [];
      for (let i = 0, n = tags.length; i < n; i++) {
        let tag = tags[i];
        if (tag) {
          list.push(tag);
        }
        if (list.length === 2) {
          break;
        }
      }
      return list.join("/");
    },
    async getClaimList() {
      const params = {
        page: 1,
        pageSize: 100,
      };
      const res = await User.getBrandList(params);
      if (res && res.code === 200) {
        this.collectList = res.data.items;
      }
    },
    claimSuccessCallback() {
      this.getClaimList();
    },
    async gotoPage(item) {
      const res = await GetDetailBrowsingRecord({
        module: "brandDetail",
        detailID: item.brandID,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/brandDetail", {
          id: item.brandID,
        });
      }
    },
    repeatClaim(item) {
      this.itemData = item;
      this.showMode = true;
    },
  },
  created() {
    this.getClaimList();
  },
  mounted() {},
  components: {
    BrandClaim,
    NoData,
  },
};
</script>
<style lang="less" scoped>
.my-claim {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  overflow: auto;
  .goods-list {
    height: calc(100% -92px);
    overflow: auto;
    .good-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      height: 120px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 32px;
        right: 32px;
        height: 1px;
        opacity: 0.3;
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .img-wrap {
          width: 80px;
          height: 80px;
          border-radius: 4px;
          overflow: hidden;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
          }
        }

        .goods-msg {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .msg-top {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            line-height: 16px;
            margin-bottom: 21px;
          }
          .msg-bottom {
            display: flex;
            .msg-item {
              margin-right: 24px;
              span {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
              }
              .msg-name {
                color: rgba(0, 0, 0, 0.4);
              }
              .msg-val {
                color: rgba(0, 0, 0, 0.6);
              }
            }
          }
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        .collect {
          // cursor: pointer;
          width: 78px;
          height: 26px;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 26px;

          margin-bottom: 19px;
        }
        .loading {
          color: #f89c00;
          background: rgba(248, 156, 0, 0.08);
        }
        .fail {
          color: #ff515a;
          background: rgba(255, 81, 90, 0.08);
        }
        .success {
          color: #52c41b;
          background: rgba(82, 196, 27, 0.08);
        }
        .hint-wrap {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .detail {
          cursor: pointer;
          margin-left: 10px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          span {
            font-size: 14px;
            font-weight: 500;
            color: #3519fb;
            line-height: 16px;
            display: inline-block;
          }
          img {
            width: 6px;
            height: 10px;
            margin-left: 6px;
          }
        }
      }
    }
  }
}
</style>
