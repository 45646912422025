var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-menu" },
    _vm._l(_vm.memnuList, function (item, index) {
      return _c(
        "div",
        {
          key: item.name + index,
          staticClass: "menu-item",
          class: {
            "active-menu-item":
              item.name === _vm.routeMsg.page && _vm.routeMsg.name === "user",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.gotoPage(item)
            },
          },
        },
        [
          _c("div", { staticClass: "bg" }),
          _c("img", {
            staticClass: "menu-img",
            attrs: { src: item.icon, alt: "" },
          }),
          _c("img", {
            staticClass: "hover-menu-img",
            attrs: { src: item.hoverIcon, alt: "" },
          }),
          _c("span", [_vm._v(_vm._s(item.title))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }