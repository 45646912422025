import { render, staticRenderFns } from "./MyCollect.vue?vue&type=template&id=872174ec&scoped=true"
import script from "./MyCollect.vue?vue&type=script&lang=js"
export * from "./MyCollect.vue?vue&type=script&lang=js"
import style0 from "./MyCollect.vue?vue&type=style&index=0&id=872174ec&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "872174ec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mnt/www/jenkins/jenkins_home/workspace/product/qianlimu/online/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('872174ec')) {
      api.createRecord('872174ec', component.options)
    } else {
      api.reload('872174ec', component.options)
    }
    module.hot.accept("./MyCollect.vue?vue&type=template&id=872174ec&scoped=true", function () {
      api.rerender('872174ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/user/MyCollect.vue"
export default component.exports