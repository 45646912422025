var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap user-wrap" },
    [
      _c("Header", { attrs: { mode: 1, isRevertIcon: true } }),
      _c("div", { staticClass: "main-wrap main-width" }, [
        _c("div", { staticClass: "user-content" }, [
          _c("div", { staticClass: "left" }, [_c("UserMenu")], 1),
          _c(
            "div",
            { staticClass: "right" },
            [_c(_vm.pathName, { tag: "component" })],
            1
          ),
        ]),
      ]),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }