var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-account" }, [
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "title" }, [_vm._v("头像")]),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "avatar-wrap" },
          [
            _c("img", {
              staticClass: "user-avatar",
              attrs: { src: _vm.avatarImg || _vm.defaultUserImg, alt: "" },
              on: {
                click: function ($event) {
                  return _vm.change("avatar")
                },
              },
            }),
            _c(
              "Upload",
              {
                attrs: {
                  action: _vm.uploadUrl,
                  "max-size": 1024,
                  "show-upload-list": false,
                  headers: _vm.uploadHeader,
                  accept: _vm.uploadAccept,
                  "on-success": _vm.uploadSuccessHandler,
                  "on-exceeded-size": _vm.exceededSizeHandler,
                  name: "avatarFile",
                },
              },
              [
                _c("div", { staticClass: "mask-avatar" }, [
                  _c("span", [_vm._v("修改")]),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "title" }, [_vm._v("用户名称")]),
      _c("div", { staticClass: "content" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.stateEditUserName,
                expression: "!stateEditUserName",
              },
            ],
            staticClass: "name",
            class: { "un-bing": !_vm.userInfo.name },
          },
          [_vm._v(_vm._s(_vm.userInfo.name || ""))]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stateEditUserName,
                expression: "stateEditUserName",
              },
            ],
            staticClass: "edit-name-wrap",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
                { name: "focusblur", rawName: "v-focusblur" },
              ],
              staticClass: "edit-name-input",
              attrs: { type: "text", placeholder: "请输入用户名称" },
              domProps: { value: _vm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
            _c(
              "span",
              {
                staticClass: "cancel-edit-name cancel-btn",
                on: { click: _vm.cancleEditUserName },
              },
              [_vm._v("取消")]
            ),
            _c(
              "span",
              {
                staticClass: "ok-edit-name main-btn",
                on: { click: _vm.okEditUserName },
              },
              [_vm._v("确定")]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.stateEditUserName,
              expression: "!stateEditUserName",
            },
          ],
          staticClass: "edit",
        },
        [
          _c(
            "div",
            {
              staticClass: "edit-btn",
              on: {
                click: function ($event) {
                  return _vm.change("userName")
                },
              },
            },
            [_vm._v("修改")]
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "title" }, [_vm._v("手机号")]),
      _c("div", { staticClass: "content" }, [
        _c(
          "span",
          { staticClass: "name", class: { "un-bing": !_vm.userInfo.phone } },
          [_vm._v(_vm._s(_vm.userInfo.phone || "未绑定"))]
        ),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "title" }, [_vm._v("邮箱")]),
      _c("div", { staticClass: "content" }, [
        _c(
          "span",
          { staticClass: "name", class: { "un-bing": !_vm.userInfo.email } },
          [_vm._v(_vm._s(_vm.userInfo.email || "未绑定"))]
        ),
      ]),
      _c("div", { staticClass: "edit" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.userInfo.email,
                expression: "userInfo.email",
              },
            ],
            staticClass: "edit-btn",
            on: {
              click: function ($event) {
                return _vm.change("email", "edit")
              },
            },
          },
          [_vm._v(" 修改 ")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.userInfo.email,
                expression: "!userInfo.email",
              },
            ],
            staticClass: "edit-btn",
            on: {
              click: function ($event) {
                return _vm.change("email", "bind")
              },
            },
          },
          [_vm._v(" 绑定 ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "title" }, [_vm._v("微信")]),
      _c("div", { staticClass: "content" }, [
        _c(
          "span",
          { staticClass: "name", class: { "un-bing": !_vm.userInfo.wechat } },
          [_vm._v(_vm._s(_vm.userInfo.wechat || "未绑定"))]
        ),
      ]),
      _c("div", { staticClass: "edit" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.userInfo.wechat,
                expression: "!userInfo.wechat",
              },
            ],
            staticClass: "edit-btn",
            on: {
              click: function ($event) {
                return _vm.change("wx", "bind")
              },
            },
          },
          [_vm._v(" 绑定 ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "title" }, [_vm._v("密码")]),
      _vm._m(0),
      _c("div", { staticClass: "edit" }, [
        _c(
          "div",
          {
            staticClass: "edit-btn",
            on: {
              click: function ($event) {
                return _vm.change("pwd")
              },
            },
          },
          [_vm._v("修改")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("span", { staticClass: "name" }, [_vm._v("******")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }