var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-collect" },
    [
      _c(
        "div",
        { staticClass: "tab-bar" },
        _vm._l(_vm.tabBar, function (item, index) {
          return _c(
            "div",
            {
              key: item.label + index,
              staticClass: "tab-item",
              class: { active: _vm.isActive === index },
              on: {
                click: function ($event) {
                  return _vm.changeTabbar(item, index)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          )
        }),
        0
      ),
      _vm.collectList.length > 0
        ? _c(
            "div",
            { staticClass: "goods-list" },
            _vm._l(_vm.collectList, function (item, index) {
              return _c("div", { key: index, staticClass: "good-item" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "img-wrap" }, [
                    item.indexType !== "data_observation_business_strict"
                      ? _c("img", {
                          attrs: { src: item.logo || _vm.defaultImg, alt: "" },
                        })
                      : _vm._e(),
                    item.indexType === "data_observation_business_strict"
                      ? _c("img", {
                          attrs: { src: _vm.showBgPic(index), alt: "" },
                        })
                      : _vm._e(),
                    item.indexType === "data_observation_business_strict"
                      ? _c("span", { staticClass: "business-name" }, [
                          _vm._v(_vm._s(item.name)),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "goods-msg" }, [
                    _c("div", { staticClass: "msg-top" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.indexType === "data_observation_brand",
                            expression:
                              "item.indexType === 'data_observation_brand'",
                          },
                        ],
                        staticClass: "msg-bottom",
                      },
                      [
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("类别："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.category || "--")),
                          ]),
                        ]),
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("所属公司："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.companyName)),
                          ]),
                        ]),
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("成立时间："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.startTime || "--")),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              item.indexType === "data_observation_location",
                            expression:
                              "item.indexType === 'data_observation_location'",
                          },
                        ],
                        staticClass: "msg-bottom",
                      },
                      [
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("地址："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.location || "--")),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.tags,
                                expression: "item.tags",
                              },
                            ],
                            staticClass: "msg-item",
                          },
                          [
                            _c("span", { staticClass: "msg-name" }, [
                              _vm._v("类别："),
                            ]),
                            _c("span", { staticClass: "msg-val" }, [
                              _vm._v(_vm._s(_vm.getTags(item.tags) || "--")),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              item.indexType ===
                              "data_observation_business_strict",
                            expression:
                              "item.indexType === 'data_observation_business_strict'",
                          },
                        ],
                        staticClass: "msg-bottom",
                      },
                      [
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("商圈级别："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.level)),
                          ]),
                        ]),
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("商圈等级："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.type)),
                          ]),
                        ]),
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("商圈商业项目："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.childrenNumber) + "个"),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.indexType === "data_observation_mall",
                            expression:
                              "item.indexType === 'data_observation_mall'",
                          },
                        ],
                        staticClass: "msg-bottom",
                      },
                      [
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("地址："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.addr)),
                          ]),
                        ]),
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("开业时间："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.startTime || "--")),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.indexType === "data_observation_region",
                            expression:
                              "item.indexType === 'data_observation_region'",
                          },
                        ],
                        staticClass: "msg-bottom",
                      },
                      [
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("城市级别："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.cityLevel)),
                          ]),
                        ]),
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("城市线级："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.cityLineLevel)),
                          ]),
                        ]),
                        _c("div", { staticClass: "msg-item" }, [
                          _c("span", { staticClass: "msg-name" }, [
                            _vm._v("常住人口："),
                          ]),
                          _c("span", { staticClass: "msg-val" }, [
                            _vm._v(_vm._s(item.residentPopulation)),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "div",
                    {
                      staticClass: "collect",
                      on: {
                        click: function ($event) {
                          return _vm.delCollect(item)
                        },
                      },
                    },
                    [_vm._v("取消收藏")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "detail",
                      on: {
                        click: function ($event) {
                          return _vm.goBrandDetail(item)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("查看详情")]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icon/arrow_blue.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.collectList.length === 0
        ? _c("NoData", {
            attrs: { img: "/images/no-data2.png", text: "暂无相关数据" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "page-wrap" },
        [
          _c("Page", {
            attrs: {
              "show-sizer": "",
              total: _vm.pageTotal,
              "show-total": "",
              "show-elevator": "",
              "page-size": _vm.pageSize,
              "page-size-opts": [_vm.pageSize],
            },
            on: {
              "on-change": _vm.handlePageChange,
              "on-page-size-change": _vm.handlerPageSizeChange,
              "on-prev": _vm.handlePageChange,
              "on-next": _vm.handlePageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }